<template>
    <div >
        <!-- ini adalah component Tambah ATK Masuk -->
        
        <!-- <template-form :initialFields="fields" >
        </template-form> -->
        <template-form  :initialFields="fields" :onSubmit="onSubmit">
            <template v-slot:notification>
                <message :type="ntf.type" :status="ntf.status" :message="ntf.message" />
            </template>
		</template-form>
    </div>
</template>

<script>
import Form from './Form.vue';
import { defaultFields } from "./helpers";
    export default {
    data() {
		/* return {
            fields: defaultFields(),
		} */
         return {
                isLoading: false,
                fields: defaultFields(),
              /*   fields: {
                    name: '',
                    address: '',
                    phone: ''
                }, */
                ntf: {
                    type: 1,
                    status: false,
                    message: ''
                }
            }
	},
    components: {
        'template-form': Form 
	},
          beforeMount() {
            /* console.log('create');
            console.log(this.fields); */
        },
    methods: {
		async onSubmit1(fields) {
			this.isLoading = true;
            console.log(fields);
            this.fields = defaultFields();
			
            
			this.isLoading = false;
		},
        async onSubmit(fields) {
                    this.isLoading = true;
                    console.log('mau submit');  
                    console.log(fields);                    
                    try {
                        const response = await this.apiPosts(`atk_out/store`, fields);
                        console.log(response);                        
                        if(response.status == 200) {
                            this.$router.push({
                                name: 'tran_atk_out'
                            });
                            return;
                        }          
                        //var notifmsg = response.data;
                        /* var teks=` 
                        <div v-for="(errorArray, index) in  `+notifmsg+`" :key="index">
                        <span class="text-danger">{{ errorArray}} </span>
                        </div>`; */
                        let teks=''; 
                        Object.keys(response.data.errors).forEach(item => {
                                    let field = response.data.errors[item];
                            for(let i = 0; i < field.length; i++){
                            teks += field[i] + "<br />";         
                            }
                        });
                       /*  var teks=response.data
                            .map(item => `<div>${item}</div>`)
                            .join('') */
                        console.log(teks);
                        this.$swal({
                                title: 'Error',
                                html: teks,
                                border: '1px solid #F0E1A1',
                                backdrop: "rgba(43, 165, 137, 0.45)",
                            
                            });                
                         
                    } catch (error) {
                        console.log(error);
                        console.log('salah');   
                        //console.log(error.response.data.errors);         
                         this.$swal({
                                title: 'Error',
                                html: error,
                                border: '1px solid #F0E1A1',
                                backdrop: "rgba(43, 165, 137, 0.45)",
                            
                            });                                       
                    }
                       
                    this.isLoading = false;
                },
        defaultFields
	}   
    }
</script>